/*=================================================================*/
/*                      CLIENTS                              
/*=================================================================*/
.knowledge {
  min-height: 50px;
  position: relative;
  text-align: center;
  margin-top: 60px;
  color: $color-white;

  .item {
    text-align: center;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    a {
      color: $color-white;
    }
  }

  img {
    display: inline-block;
  }
}
