// FONTS
$font-family: "Rubik", sans-serif;

$font-size-smaller: 14px;
$font-size-normal: 16px;
$font-size-larger: 18px;
$font-size-x-large: 24px;
$font-size-xx-large: 28px;
$font-size-largest: 36px;
$font-size-huge: 48px;

$font-color: #021822;
$font-color-dark: #021822;

$font-bold: 700;

// COLORS
$color-light: #2a516c;
$color-dark: #021822;
$color-white: #fff;
$color-active: #3f87a6;
$color-primary: #f06000;
$color-bck-x-light: #ffeed3;
$color-bck-dark: #04334b;
