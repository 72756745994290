/*=================================================================*/
/*                     BUTTONS
/*=================================================================*/

.btn {
  border-radius: 30px;
  font-family: $font-family;
  font-size: $font-size-normal;
  font-weight: $font-bold;
  overflow: hidden;
  line-height: 1;
  padding: 1rem 2rem;
  position: relative;

  &:focus {
    box-shadow: none;
  }

  &:focus {
    outline: 0;
  }

  &.disabled,
  &:disabled {
    opacity: 1;
    cursor: not-allowed;
  }
}

.btn-kd {
  color: $color-white;

  background: $color-primary;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);

  &:hover {
    color: $color-white;
    background: $color-active;
  }
}
