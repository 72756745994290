/*=================================================================*/
/*                      CONTACT                              
/*=================================================================*/
.contact-info {
  background: url(../../../public/images/map.svg);
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 200px;
  margin-bottom: 1rem;
}
.contact-info h3 {
  font-size: 24px;
  margin: 0 0 10px;
}

/* === Form Control === */

.kd-form-control,
.kd-form-control:focus {
  box-shadow: inset 2px 2px 4px #bdbdbd, inset -2px -2px 4px #ffffff;
}

.kd-form-control {
  display: block;
  border-radius: 2rem;
  width: 100%;
  height: 4rem;
  padding: 1.2rem 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: $color-light;
  background-color: $color-white;
  background-image: none;
  border: 0;
  margin-bottom: 1.5rem;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: $color-active;
  }
}

.textarea {
  min-height: 10rem;
}
.input-errors {
  padding: 0 0 0.2rem 2rem;
  color: $color-primary;
}

.form-group {
  margin-bottom: 2rem;
}
