/*=================================================================*/
/*                      SECTIONS                             
/*=================================================================*/

section {
  padding-top: 110px;
  position: relative;

  &.about {
    padding: 0;
    height: 100vh;
    min-height: 100vh;
  }
}

#home {
  background: url(../../../public/images/Dune.webp);
  background-size: cover;
  background-position: center;
  padding: 0;
  height: 100vh;
  min-height: 100vh;

  .textloop {
  }

  .intro {
    margin: auto;
    max-width: 540px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    justify-content: center;

    h1 {
      color: $color-white;
      font-size: $font-size-huge;
    }

    span {
      color: $color-white;
      font-size: $font-size-larger;
    }
  }

  .social-icons {
    li {
      a {
        color: $color-white;
      }
    }
  }
}

#about {
  background: linear-gradient(0.25turn, $color-bck-dark, $color-light);
  min-height: 100vh;

  img {
    width: 150px;
    height: 150px;
  }

  .text-center {
    color: $color-primary;
  }

  .alignement {
    display: flex;
    justify-content: space-around;
  }

  .color-white {
    color: $color-white;
  }

  .fact-item {
    margin: 0 0.5rem 2rem 0.5rem;
  }
}

#education,
#experience {
  h2 {
    color: $color-dark;
  }

  h3 {
    color: $color-active;
  }
  margin-bottom: 3rem;
}

#services {
  h2 {
    color: $color-dark;
  }
}

#recommendations {
  background: linear-gradient(0.25turn, $color-bck-dark, $color-light);
  min-height: 100vh;

  .avatar {
    border-radius: 50%;
  }

  .subtitle {
    color: $color-white;
  }
}

#contact {
  min-height: calc(100vh - 217px);
  h2 {
    color: $color-dark;
  }
  h3 {
    color: $color-active;
  }
}

.icons {
  max-height: 50px;
}

.item p {
  padding: 0.5rem 0 1.5rem 0;
}

.section-title {
  font-size: $font-size-largest;
  margin: 0;
  position: relative;
}

.display {
  display: none;
}

.validation {
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(4px);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  .box {
    background-color: $color-dark;
    color: $color-white;
    border-radius: 10px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.8);
    margin: auto auto;
    padding: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
  }
}
